<template>
  <div class="admin-detail">
    <div class="admin-navbar justify-between">
      <button class="action-button" @click="$router.go(-1)">{{ $lang.app.back }}</button>
      <div class="detail-buttons">
        <button v-if="editable" class="detail-button"
                @click="$router.push(`/admin/groups/${$route.params.id}/edit`)">
          {{ $lang.app.edit }}</button>
      </div>
    </div>
    <div v-if="group" class="admin-info">
      <h4 class="info-title">{{ $lang.app.groups }}</h4>
      <div class="info-fields">
        <div v-if="$store.getters.isPreschool"
             class="info-field">
          <p class="field-label">{{ $lang.app.title }}:</p>
          <p class="field-value">{{ group.name }}</p>
        </div>
        <div v-if="$store.getters.isPreschool"
             class="info-field">
          <p class="field-label">{{ $lang.app.category }}:</p>
          <p v-if="group.preschoolGroupType" class="field-value">{{ $lang.$translate({ru: group.preschoolGroupType.nameRu, kg: group.preschoolGroupType.nameKg }) }}</p>
        </div>
        <div class="info-field">
          <p class="field-label">{{ $lang.app.education_language }}:</p>
          <p class="field-value">{{ group.language.name }}</p>
        </div>
        <div class="info-field">
          <p class="field-label">{{ $lang.app.position_quantity }}:</p>
          <p class="field-value">{{ group.quota }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      group: null,
    }
  },
  computed: {
    editable() {
      return this.$store.getters.isAdmin
    }
  },
  methods: {
    fetchResource() {
      this.$axios.get(`/organization-group/${this.$route.params.id}`).then(({data}) => {
        this.group = data
      })
    }
  },
  mounted() {
    this.fetchResource()
  }
}
</script>
<style>
@import '../../../assets/styles/admin/detail.css';
@import '../../../assets/styles/admin/info.css';
@import '../../../assets/styles/admin/navbar.css';
</style>